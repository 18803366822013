import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-grid-system";
// @ts-ignore
import { geoMiller } from "d3-geo-projection"

import WorldMap from "react-svg-worldmap";
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps"
import { geoMercator, geoPath, geoContains, geoCentroid, geoNaturalEarth1, geoNaturalEarth1Raw, GeoPermissibleObjects } from "d3-geo";
import { debounce } from "lodash";

import styles from './styles.module.scss'

import geo from './geo'
import useWindowDimensions from "../../../../shared/hooks/useDimensions";
import ZoomableMobile from "./ZoomableMobile";
import CustomMarker from "./Marker";

const Map = () => {
  const width = window.innerWidth

  const data = [
    { country: "cn", value: 1389618778 }, // china
    { country: "in", value: 1311559204 }, // india
    { country: "us", value: 331883986 }, // united states
    { country: "id", value: 264935824 }, // indonesia
    { country: "pk", value: 210797836 }, // pakistan
    { country: "br", value: 210301591 }, // brazil
    { country: "ng", value: 208679114 }, // nigeria
    { country: "bd", value: 161062905 }, // bangladesh
    { country: "ru", value: 141944641 }, // russia
    { country: "mx", value: 127318112 }, // mexico
  ];


  const subregions = {
    "Northern Europe": {
      cords: null,
      projects: 197,
      color: "#9e9e9e5c",
      marker: "#27ae60"
    },
    "Western Europe": {
      cords: null,
      projects: 529,
      color: "#abb2b9",
      marker: "#95a5a6"
    },
    "Southern Europe": {
      cords: null,
      projects: 203,
      color: "#bfc9ca",
      marker: "#000"
    },
    "Eastern Europe": {
      cords: null,
      projects: 2324,
      color: "#85929e",
      marker: "#95a5a6"
    },

    "Western Asia": {
      cords: null,
      projects: 724,
      color: "#b2babb",
      marker: '#e74c3c'
    },
    "Central Asia": {
      cords: null,
      projects: 1238,
      color: "#eaecee",
      marker: "#2c3e50",
    },
    "Eastern Asia": {
      cords: null,
      projects: 173,
      color: "#a9cce3",
      marker: "#f39c12"

    },
    "Southern Asia": {
      cords: null,
      projects: 142,
      color: "#9ea7b2",
      marker: '#3498db'
    },
    "Southeastern Asia": {
      cords: null,
      projects: 102,
      color: "#c7c7c7",
      marker: '#3498db'
    },
    "Northern Africa": {
      cords: null,
      projects: 0,
      color: "#c2e0f4",
      marker: "#fff"
    },
    "Eastern Africa": {
      cords: null,
      projects: 0,
      color: "#c2e0f4",
      marker: "#fff"
    },
    "Western Africa": {
      cords: null,
      projects: 0,
      color: "#c2e0f4",
      marker: "#fff"
    },
    "Central Africa": {
      cords: null,
      projects: 0,
      color: "#c2e0f4",
      marker: "#fff"
    },
    "Southern Africa": {
      cords: null,
      projects: 0,
      color: "#c2e0f4",
      marker: "#fff"
    },

    "Northern America": {
      cords: null,
      projects: 0,
      color: "#c2e0f4",
      marker: "#fff"
    },
    "Central America": {
      cords: null,
      projects: 0,
      color: "#c2e0f4",
      marker: "#fff"
    },
    "South America": {
      cords: null,
      projects: 0,
      color: "#c2e0f4",
      marker: "#fff"
    },

    "Antarctica": {
      cords: null,
      projects: 0,
      color: "#c2e0f4",
      marker: "#fff"
    },
    "Oceania": {
      cords: null,
      projects: 0,
      color: "#c2e0f4",
      marker: "#fff"
    },
  }

  type SubregionsType = keyof typeof subregions

  const projectsCount = Object.values(subregions).reduce(
    (accumulator, currentValue) => accumulator + currentValue.projects ?? 0,
    0,
  )
  useEffect(() => {
  }, [])

  const [populationData, setPopulationData] = useState(false)
  const [optimizationDisabled, setOptimizationDisabled] = useState(false)

  const switchDataset = () => {
    setOptimizationDisabled(true)
    setPopulationData(prev => !prev)
  }
  const width1 = 800
  const height = 600

  const projection = geoMiller()
    .rotate([-10, 0])

    .translate([width1 / 2, height / 2])
    .scale(120)

  const getCoords = (props: any, provinceCenter: number[]): [number, number] => {
    // if()
    if (props.subregion == 'Central Asia') {
      return [provinceCenter[0], provinceCenter[1] + 5]
    }
    if (props.subregion == 'Western Europe') {
      return [provinceCenter[0] - 2, provinceCenter[1] + 2]
    }
    return [provinceCenter[0], provinceCenter[1]]
  }

  const roundBorders = (geo: GeoPermissibleObjects) => {
    const path = geoPath().projection(geoNaturalEarth1());
    const roundedPath = (feature: GeoPermissibleObjects) => {
      return path(feature)?.replace(/(\d+)\.(\d+)/g, (match, p1, p2) => {
        const rounded = Math.round(Number(`${p1}.${p2}`));
        return `${rounded}`;
      });
    };
    return { ...geo };
  };
  const [hoveredSubregion, setHoveredSubregion] = useState<SubregionsType | ''>('')
  const handleMouseEnter = (subregion: SubregionsType) => {
    setHoveredSubregion(subregion);
  };
  const debouncedHandleMouseEnter = debounce(handleMouseEnter, 200)

  const handleMouseLeave = () => {
    setHoveredSubregion('');
  };

  return (
    <div style={{
      background: '#C3E8FF',
      padding: 0,
    }}
      className={styles.test}
    >
      <Container className={styles.map} style={{
        maxWidth: 1156.7
      }}>
        <div>
          <h3 className={styles.projects}>
            Total projects: {projectsCount}
          </h3>
        </div>
        <Row>
          <Col>
            <ComposableMap
              projection={projection}
              projectionConfig={{
                scale: 100
              }}
            >
              <ZoomableMobile>
                <Geographies geography={geo}
                  style={{
                    background: 'red'
                  }}>
                  {({ geographies }) =>
                    <>
                      {geographies.map((geo, i) => {
                        if (geo.properties.shouldShowForCurrentRegion) {
                          const provinceCenter = geoCentroid(geo);
                          console.log(geo.properties.subregion, provinceCenter)
                          const cords = getCoords(geo.properties, provinceCenter)
                          if (subregions[geo.properties.subregion as SubregionsType]) {
                            (subregions[geo.properties.subregion as SubregionsType].cords as unknown as number[]) = cords
                          }
                          // cords
                        }
                        const subReg = geo.properties.subregion as keyof typeof subregions


                        const color = subReg ? subregions[subReg].color : '#14346F'
                       
                        const marker = subReg ? subregions[subReg].marker : '#14346F'

                        return (
                          <>
                            <Geography
                              strokeWidth={0.3}
                              onMouseEnter={(e) => {
                                if (subregions[geo.properties.subregion as SubregionsType].projects) {
                                  debouncedHandleMouseEnter(geo.properties.subregion)
                                }
                              }}
                              key={geo.rsmKey} 
                              geography={(() => {
                                return geo
                              })()} 
                              style={{
                                default: {
                                  transition: 'all 0.5s',
                                  fill: color,
                                  // fill: subregions[geo.properties.subregion],
                                  outline: 'none'
                                },
                                hover: {
                                  // transition: 'all 0.5s',
                                  fill: color,
                                  outline: 'none'
                                },
                                pressed: {
                                  fill: color,
                                  outline: 'none'
                                }
                              }} />

                            {geo.properties.shouldShowForCurrentRegion && geo.properties.subregion && subregions?.[geo.properties.subregion as SubregionsType].projects ? (
                              <>
                                <CustomMarker
                                  key={geo.rsmKey}
                                  hover={geo.properties.subregion == hoveredSubregion}
                                  label={subregions?.[geo.properties.subregion as SubregionsType].projects}
                                  cords={subregions?.[geo.properties.subregion as SubregionsType].cords as unknown as [number, number]}
                                />
                              </>
                            ) : null}
                          </>
                        )
                      })}
                    </>
                  }
                </Geographies>
              </ZoomableMobile>
            </ComposableMap>
          </Col>
        </Row>
      </Container>
    </div >
  )

}

export default Map
